import React from "react";
import Slider from "react-slick";

import Product from "../../components/elements/products/Product";

const CategoryProduct = ({ products, currencyrate }) => {
  /*
    To carousel setting
  */
  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  /*
    To set carouse for category vise product view 
  */
  let categoryitem = [];
  if (products && products.length > 0) {
    products.map((items) => {
      let carouselitem = [];
      if (items.products && items.products !== null && items.products !== "" && items.products.length > 0) {
        var categoryproducy = items.products;
        var isnamkeen = "";
        var namkeendiv = "";
        if (items.categoryname === "Namkeen" || items.categoryname === "NAMKEEN") {
          isnamkeen = "namkeen";
          namkeendiv = "namkeen-div";
        }
        categoryproducy.map((item) => {
          carouselitem.push(
            <div className="carousel-item ps-layout--grid ps-shop-items bn shop-items-list similar-product producteffect">
              <div className="ps-category__item m-10">
                <div className={"ps-layout__item card-box-shadow bn " + isnamkeen} key={item.id}>
                  <Product product={item} currencyrate={currencyrate} />
                </div>
              </div>
            </div>
          );
        });
      }

      categoryitem.push(
        <div className={"ps-page ps-page--product home-section-div " + namkeendiv}>
          {/* <div>
            <img src="/static/img/static_image/Chakri.png" className="chakri-img"></img>
          </div>
          <div>
            <img src="/static/img/static_image/namkeen.png" className="cookie10-img"></img>
          </div>  */}
          <div className="container">
            <div className="ps-page__content">
              <div className=" ps-reverse">
                <div className="ps-layout__right">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h3 className="sectionname ">Fresh and healthy</h3>
                    </div>

                    <div className="col-12 text-center">
                      <h3 className="ps-section__title pb-20 selection-title">{items.categoryname}</h3>
                    </div>
                    <div className="col-12">
                      <Slider {...carouselSetting} className="ps-carousel home-slider">
                        {carouselitem}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return <>{categoryitem ? categoryitem : ""}</>;
};
export default CategoryProduct;
