import React from "react";
import Slider from "react-slick";

import Product from "../../components/elements/products/Product";

const FeaturedProduct = ({ featured, currencyrate, homedetail }) => {
  /*
    To featured product view
  */
  let carouselitem = [];
  if (featured && featured.length > 0) {
    featured.map((item, index) => {
      item.productid = item.id;
      carouselitem.push(
        <div className="carousel-item ps-layout--grid ps-shop-items bn shop-items-list similar-product producteffect">
          <div className="ps-category__item m-10">
            <div className="ps-layout__item card-box-shadow bn" key={index}>
              <Product product={item} currencyrate={currencyrate} />
            </div>
          </div>
        </div>
      );
    });
  }

  /*
    To carousel setting
  */
  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {carouselitem && carouselitem.length > 0 && (
        <div className="ps-page ps-page--product home-section-div">
          {/* <div>
            <img src="/static/img/static_image/kaju.png" className="cookie-img"></img>
          </div>
          <div>
            <img src="/static/img/static_image/cookie-1.png" className="cookie1-img"></img>
          </div>  */}

          <div className="container">
            <div className="ps-page__content">
              <div className=" ps-reverse">
                <div className="ps-layout__right">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h3 className="sectionname">{homedetail.displayfeaturedprodsubtitle}</h3>
                    </div>
                    <div className="col-12 text-center">
                      <h3 className="ps-section__title pb-20 selection-title">{homedetail.displayfeaturedprodtitle}</h3>
                    </div>
                    <div className="col-12">
                      <Slider {...carouselSetting} className="ps-carousel home-slider">
                        {carouselitem}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default FeaturedProduct;
