import "antd/dist/antd.compact.min.css";

import React from "react";
import { CookiesProvider } from "react-cookie";
import { Route, Routes } from "react-router-dom";

import MasterLayout from "./components/layouts/MasterLayout";
import DrawerPrimary from "./components/shared/drawers/DrawerPrimary";
import AccountAddress from "./pages/address/AccountAddress";
import CartOrderSummary from "./pages/cart/CartOrderSummary";
import OrderFailed from "./pages/cart/order/OrderFailed";
import OrderSuccess from "./pages/cart/order/OrderSuccess";
import ShoppingCart from "./pages/cart/ShoppingCart";
import ContactUs from "./pages/contactus/ContactUs";
import Error_404 from "./pages/error/Error_404";
import AboutUs from "./pages/footer/AboutUs";
import Faqs from "./pages/footer/Faqs";
import PrivacyPolicy from "./pages/footer/PrivacyPolicy";
import ReturnPolicy from "./pages/footer/ReturnPolicy";
import TermsConditions from "./pages/footer/TermsConditions";
import ForgotPassword from "./pages/forgotpassword/Forgotpassword";
import PasswordSet from "./pages/forgotpassword/Setpassword";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import OrderDetail from "./pages/order/OrderDetail";
import OrderList from "./pages/order/OrderList";
import OrderReturn from "./pages/order/OrderReturn";
import TrackMyOrder from "./pages/order/TrackMyOrder";
import Otp from "./pages/otp/Verifyopt";
import ProductDetailPage from "./pages/product/ProductDetailPage";
import ProductPreview from "./pages/product/ProductPreview";
import ProductList from "./pages/product/Products";
import Help from "./pages/profile/Help";
import Logout from "./pages/profile/Logout";
import MyCart from "./pages/profile/MyCart";
import MyProfile from "./pages/profile/MyProfile";
import MyWishlist from "./pages/profile/MyWishlist";
import PasswordChange from "./pages/profile/PasswordChange";
import Signup from "./pages/signup/Signup";
import WishlistScreen from "./pages/wishlist/Wishlist";
import Blog from "./pages/footer/Blog";
import BlogDetail from "./pages/footer/BlogDetail";
import { wrapper } from "./store/store";

import Checkout from "./pages/cart/Checkout";
import "./public/static/css/bootstrap.min.css";
import "./public/static/css/slick.min.css";
import "./public/static/css/style.min.css";
import "./public/static/fonts/feather-font/css/iconfont.css";
import "./public/static/fonts/font-awesome/css/font-awesome.min.css";
import "./public/static/fonts/Linearicons/Font/demo-files/demo.css";
import "./styles/platform/custom.scss";
import "./styles/platform/custom/mobile.scss";
import "./styles/platform/themes/home-one.scss";
import "./styles/scss/home-1.scss";

function App({ Component, pageProps }) {
  return (
    <CookiesProvider>
      <div id="homepage-one">
        <DrawerPrimary />
        <MasterLayout />
        <main>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path={"/privacy"} element={<PrivacyPolicy />}></Route>
            <Route exact path="/login" element={<Login />}></Route>
            <Route exact path="/signup" element={<Signup />}></Route>
            <Route exact path="/otpverification" element={<Otp />}></Route>
            <Route exact path={"/shop"} element={<ProductList />}></Route>
            <Route path={"/shop/:pageno?/:sortby?/:minimumprice?/:maximumprice?"} element={<ProductList />}></Route>
            <Route exact path="/setpassword" element={<PasswordSet />}></Route>
            <Route path={"/product/:id"} element={<ProductDetailPage />}></Route>
            <Route path={"/product/preview/:id"} element={<ProductPreview />}></Route>
            <Route exact path="/forgetpassword" element={<ForgotPassword />}></Route>
            <Route exact path="/wishlist" element={<WishlistScreen />}></Route>
            <Route exact path="/cart" element={<ShoppingCart />}></Route>
            <Route exact path="/checkout" element={<Checkout />}></Route>
            <Route exact path="/profile" element={<MyProfile />}></Route>
            <Route exact path="/passwordchange" element={<PasswordChange />}></Route>
            <Route exact path="/mycart" element={<MyCart />}></Route>
            <Route exact path="/mywishlist" element={<MyWishlist />}></Route>
            <Route exact path="/myaddress" element={<AccountAddress />}></Route>
            <Route exact path="/ordersummary" element={<CartOrderSummary />}></Route>
            <Route exact path="/ordersuccess" element={<OrderSuccess />}></Route>
            <Route exact path="/orderfailed" element={<OrderFailed />}></Route>
            <Route exact path="/order" element={<OrderList />}></Route>
            <Route path={"/order/:id"} element={<OrderDetail />}></Route>
            <Route path={"/aboutus"} element={<AboutUs />}></Route>
            <Route path={"/returnpolicy"} element={<ReturnPolicy />}></Route>
            <Route path={"/privacypolicy"} element={<PrivacyPolicy />}></Route>
            <Route path={"/termsconditions"} element={<TermsConditions />}></Route>
            <Route path={"/contactus"} element={<ContactUs />}></Route>
            <Route path={"/faqs"} element={<Faqs />}></Route>
            <Route path={"/blogs"} element={<Blog />}></Route>
            <Route path={"/blog/:id"} element={<BlogDetail />}></Route>
            <Route path={"/orderreturn/:id"} element={<OrderReturn />}></Route>
            <Route path="*" element={<Error_404 />} />
            <Route path="/help" element={<Help />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/trackmyorder" element={<TrackMyOrder />} />
          </Routes>
        </main>
      </div>
    </CookiesProvider>
  );
}

export default wrapper.withRedux(App);
