import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import BreadCrumb from "../../components/elements/BreadCrumb";
import NoDataFound from "../../components/common/NoDataFound";

const Blog = () => {
  const navigate = useNavigate();
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Blogs",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, "")
      .replace(/\s+/g, "-");
  };

  const bloglist = [
    {
      id: 1,
      title: "CDS Stores Vapi: A Sweet & Savory Sensation!",
      previewDescription: "From Humble Beginnings to a Vapi Expansion",
      detailDescription:
        "Vapi, get ready to indulge! CDS Stores has arrived, bringing with it a delectable fusion of traditional Indian snacks, premium chocolates and sweets, and a brand-new fastfood experience. We opened our doors on June 10th, 2024, ready to tantalize your taste buds and become your one-stop shop for all things delicious.",
      image: "/static/img/blog/cdsblog/blog image 4.JPG",
    },
  ];

  bloglist[0].slug = generateSlug(bloglist[0].title);

  return (
    <Container>
      <GoogleCaptcha />
      <div className="container">
        <div className="">
          <div className=""></div>
        </div>
      </div>
      <div className="ps-page  form-loader blog-list-page">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>

        <div className="container blog-list-container">
          <div className="ps-page__header ">
            <BreadCrumb breacrumb={breadcrumb} />
          </div>
          <div className="ps-page__content ps-account">
            <div className="blog--one">
              <div className="container p-0">
                <div className="blog--one-container">
                  <div className="blog--one-content-container">
                    <>
                      <div className="blog-section--one">
                        {bloglist && bloglist.length > 0 && (
                          <div className="row m-0 blog-section--one-container">
                            <div className="col-5 left-content">
                              <div className="img-container">
                                <img src={bloglist[0].image} className="bg-img" />
                              </div>
                            </div>
                            <div className="col-7 right-content">
                              <div className="blog-content-container">
                                <div className="blog-title">
                                  <p>{bloglist[0].previewDescription}</p>
                                  <h2
                                    onClick={() =>
                                      navigate(`/blog/${bloglist[0].slug}`, { state: { title: bloglist[0].title } })
                                    }
                                  >
                                    {bloglist[0].title}
                                  </h2>
                                </div>
                                <div className="blog-text">
                                  <p>{bloglist[0].detailDescription}</p>
                                </div>

                                <div className="read-btn">
                                  <button
                                    onClick={() =>
                                      navigate(`/blog/${bloglist[0].slug}`, { state: { title: bloglist[0].title } })
                                    }
                                  >
                                    Read Full Blog
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Blog;
