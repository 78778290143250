import React, { useEffect } from "react";
import Slider from "react-slick";

const HomeVideos = () => {
  const videos = [
    { id: 1, src: "/static/img/home/1.mp4" },
    { id: 2, src: "/static/img/home/2.mp4" },
    { id: 3, src: "/static/img/home/3.mp4" },
    { id: 4, src: "/static/img/home/4.mp4" },
    { id: 5, src: "/static/img/home/5.mp4" },
  ];

  const settings = {
    dots: false,
    infinite: videos.length > 5 ? true : false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // Ensure videos are unique in the slider
    const sliderItems = document.querySelectorAll(".video-slider .video-item video");
    const seenSrcs = new Set();

    sliderItems.forEach((item) => {
      const videoSrc = item.getAttribute("src");
      if (seenSrcs.has(videoSrc)) {
        item.closest(".slick-slide").classList.add("duplicate-slide");
      } else {
        seenSrcs.add(videoSrc);
      }
    });
  }, [videos]);

  return (
    <div className="home-video-section">
      <div className="container">
        <div className="home-video-section-container">
          <div className="video-container ">
            <Slider {...settings}>
              {videos &&
                videos.length > 0 &&
                videos.map((item) => (
                  <div className="video-content" key={item.id}>
                    <div className="video--card">
                      <a className="video-image" href="https://cdsstores.in/product/1PBeFLUJ">
                        <video playsInline src={item.src} autoPlay loop muted className="video-element " />
                      </a>
                      <a href="https://cdsstores.in/product/1PBeFLUJ" className="video-link-btn">
                        Shop Now
                      </a>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeVideos;
