import React from "react";

import config from "../../../../config/config.json";
const PopularCategoriesTwo = ({ category, homedetail }) => {
  /*
    To category list view
  */
  let categorylist = [];
  if (category !== null && category !== "") {
    category.map((item, index) => {
      let imagepath = config.apiUrl + config.image_path;
      if (item.imagename !== null && item.imagename !== "") {
        imagepath = imagepath + item.imagename;
      }

      var classname = "effect-goliath";
      if (Number(index) === 3) {
        classname = classname + " grid-figure-secound";
      } else if (Number(index) > 3 && Number(index) < 6) {
        classname = classname + " grid-figure-last";
      }
      categorylist.push(
        <figure className={classname}>
          <img src={imagepath} alt="img23" />
          <figcaption>
            <h2>
              <span>{item.categoryname}</span>
            </h2>
            <p className="cat-view-btn">View more</p>
            <a href={"/shop?parentcategory=" + item.id}>View more</a>
          </figcaption>
        </figure>
      );
    });
  }

  /*
    To category list view
  */
  let categorylist1 = [];
  if (category !== null && category !== "") {
    category.map((item, index) => {
      let imagepath = config.apiUrl + config.image_path;
      if (item.imagename !== null && item.imagename !== "") {
        imagepath = imagepath + item.imagename;
      }

      var classname = "effect-goliath";
      categorylist1.push(
        <figure className={classname}>
          <img src={imagepath} alt="img23" />
          <figcaption>
            <h2>
              <span>{item.categoryname}</span>
            </h2>
            <p className="cat-view-btn">View more</p>
            <a href={"/shop?parentcategory=" + item.id}>View more</a>
          </figcaption>
        </figure>
      );
    });
  }

  return (
    <>
      {/* <div className="ps-section--categories home-section-category">
        <div className="container">
          <div className="col-12 text-center">
            <h3 className="sectionname">{homedetail.displayproductcatsubtitle}</h3>
          </div>
          <div className="col-12 text-center">
            <h3 className="ps-section__title pb-20 selection-title">{homedetail.displayproductcattitle}</h3>
          </div>
          <div className="ps-section__content">
            <div className="ps-categories__list">
              <div className="category-fig">
                <div className="grid">{categorylist}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ps-section--categories home-section-category">
        <div className="container">
          <div className="col-12 text-center">
            <h3 className="sectionname">{homedetail.displayproductcatsubtitle}</h3>
          </div>
          <div className="col-12 text-center">
            <h3 className="ps-section__title pb-20 selection-title">{homedetail.displayproductcattitle}</h3>
          </div>
          <div className="category-grid-3">
            <div className="ps-section__content ">
              <div className="ps-categories__list">
                <div className="row m-0 category-fig">
                  <div className="col-12 p-0">
                    <div className="grid">{categorylist1}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="ps-section--categories home-section-category">
        <div className="container">
          <div className="col-12 text-center">
            <h3 className="sectionname">{homedetail.displayproductcatsubtitle}</h3>
          </div>
          <div className="col-12 text-center">
            <h3 className="ps-section__title pb-20 selection-title">{homedetail.displayproductcattitle}</h3>
          </div>
          <div className="category-grid-4">
            <div className="ps-section__content ">
              <div className="ps-categories__list">
                <div className="row m-0 category-fig">
                  <div className="col-12 p-0">
                    <div className="grid">{categorylist1}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularCategoriesTwo;
