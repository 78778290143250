import React from "react";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import BreadCrumb from "../../components/elements/BreadCrumb";
import { useLocation, useParams } from "react-router-dom";

const BlogDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const blogTitle = location.state?.title;
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      id: 1,
      text: "Home",
      url: "/",
    },
    {
      id: 2,
      text: "Blog",
      url: "/blogs",
    },
    {
      id: 3,
      text: blogTitle,
    },
  ];

  const blogdetail = [
    {
      id: id,
      title: "CDS Stores Vapi: A Sweet & Savory Sensation!",
      previewDescription: "From Humble Beginnings to a Vapi Expansion:",
      detailDescription:
        "Vapi, get ready to indulge! CDS Stores has arrived, bringing with it a delectable fusion of traditional Indian snacks, premium chocolates and sweets, and a brand-new fastfood experience. We opened our doors on June 10th, 2024, ready to tantalize your taste buds and become your one-stop shop for all things delicious.",
      image: "/static/img/blog/cdsblog/blog image 4.JPG",
      date: "01.02.2025",
      totalview: 100,
      totalcomment: 10,
      allowcomment: 1,
    },
  ];

  return (
    <Container>
      <GoogleCaptcha />
      <div className="blog-detail--one">
        <div className="container">
          <div className="blog-detail-content-container">
            <div className="breadcrums-container">
              <BreadCrumb breacrumb={breadcrumb} />
            </div>
            <div className="container p-0">
              <div className="blog-detail-container">
                <div className="container p-0">
                  <div className="row m-0 blog-section-container">
                    <div className="col-12 left-section">
                      <div className="container p-0">
                        {blogdetail &&
                          blogdetail.map((item) => (
                            <div className="left-section-content-container">
                              <div className="container p-0">
                                <div className="blog-title-container">
                                  <h1>{item.title}</h1>
                                  <span className="views">{item.date}</span>
                                </div>
                              </div>
                              <div className="blog-data-container">
                                <div className="blog-data--section--one">
                                  <div className="row m-0 data-section-one-container">
                                    <div className="col-12 p-0 left-section-data">
                                      <div className="blog-img-container">
                                        <img src={item.image} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="container p-0">
                                    <div className="row m-0 data-section-one-description">
                                      <div className="container p-0">
                                        <div className="blog-desciption mt-20">
                                          <div className="mb-10 font-size-18 subtitle">{item.previewDescription}</div>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: item.detailDescription,
                                            }}
                                          />
                                          <br />
                                          <p>
                                            Our journey began in 1992 with the founding of "Charliee" Chikki & Snacks by
                                            the visionary Mr. Shantilal Kalyanji Savla & Mrs. Jayaben Shantilal Savla.
                                            Their mission: to share the goodness of traditional Indian snacks with the
                                            world. Driven by core values, unwavering business ethics, simple work
                                            principles, quality ingredients, and a hygienic work culture, we’ve grown
                                            into a leading manufacturer and exporter of these beloved treats. Now, that
                                            same commitment to quality and taste comes to Vapi with the opening of CDS –
                                            Charliee Day To Day Stores.
                                          </p>
                                          <br />
                                          <p className="title-text">More Than Just Sweets:</p>
                                          <br />
                                          <p>
                                            CDS Stores is more than just your average sweet shop. We're a gourmet haven!
                                            We offer a wide selection of premium chocolates and exquisite sweets,
                                            carefully curated to satisfy every craving. Whether you're celebrating a
                                            special occasion, looking for a thoughtful gift, or simply treating
                                            yourself, our selection has you covered. And, as a recognized and trusted
                                            healthy snack brand, we offer the perfect blend of tradition and innovation.
                                          </p>
                                          <br />
                                          <div className="blog-img-container">
                                            <img src="/static/img/blog/cdsblog/blog image 2.JPG" />
                                          </div>
                                          <br />
                                          <p className="title-text">A Flavorful Fast-Food Twist:</p>
                                          <br />
                                          <p>
                                            But that's not all! Our Vapi location introduces a brand-new dimension to
                                            the CDS experience: a fast-food counter serving up premium snacks! Get ready
                                            to enjoy mouthwatering pizzas, juicy burgers, satisfying sandwiches,
                                            flavorful rolls, and, of course, our signature chaat. We've brought the same
                                            dedication to quality ingredients and delicious flavors to our fast-food
                                            offerings, ensuring a truly premium snacking experience.
                                          </p>
                                          <br />
                                          <p className="title-text">A One-Stop Shop for Every Craving:</p>
                                          <br />
                                          <p>
                                            From traditional Indian snacks that evoke nostalgia to premium chocolates
                                            and sweets that satisfy your sweet tooth, and now, delicious fast food that
                                            hits the spot, CDS Stores Vapi truly has it all. We're your one-stop shop
                                            for every craving, offering a diverse selection that goes beyond the
                                            ordinary.
                                          </p>
                                          <br />
                                          <div className="blog-img-container">
                                            <img src="/static/img/blog/cdsblog/blog image 3 .JPG" />
                                          </div>
                                          <br />
                                          <p className="title-text">Visit Us Today!</p>
                                          <br />
                                          <p>
                                            We invite you to experience the magic of CDS Stores Vapi for yourself. Come
                                            explore our extensive selection of sweets, chocolates, healthy snacks, and
                                            our exciting new fast-food menu.
                                            <br />
                                            <br /> We're located at: Shop No. 1, 2, 3, 4, Opp Ashadham School, Koparli
                                            Road, Vapi Station Road, Vapi, Gujarat 396191
                                          </p>

                                          <p>
                                            Store Hours: <span> 10 am – 10 pm</span>
                                          </p>
                                          <p>
                                            Contact Us: <span> 9512277746</span>
                                          </p>
                                          <br />
                                          <p>
                                            We can't wait to welcome you! Follow us on social media for updates and
                                            more!
                                            <br />
                                            #CDSStores #Vapi #GrandOpening #Fashion #NewStore #Valsad #Sweets
                                            #Chocolates #FastFood #IndianSnacks #Charliee
                                          </p>
                                          <br />
                                          <br />
                                          <div className="blog-imgs-container">
                                            <img src="/static/img/blog/cdsblog/blog image 1.JPG" />
                                            <img src="/static/img/blog/cdsblog/blog image 5 .JPG" />
                                            <img src="/static/img/blog/cdsblog/blog image 6.JPG" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BlogDetail;
